exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-items-service-index-js": () => import("./../../../src/pages/items/service/index.js" /* webpackChunkName: "component---src-pages-items-service-index-js" */),
  "component---src-pages-privacy-index-js": () => import("./../../../src/pages/privacy/index.js" /* webpackChunkName: "component---src-pages-privacy-index-js" */),
  "component---src-pages-term-index-js": () => import("./../../../src/pages/term/index.js" /* webpackChunkName: "component---src-pages-term-index-js" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2022-ao-man-winner-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/2022-ao-man-winner/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2022-ao-man-winner-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2022-ao-medvedev-interview-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/2022-ao-medvedev-interview/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2022-ao-medvedev-interview-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2022-ao-woman-winner-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/2022-ao-woman-winner/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2022-ao-woman-winner-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-2022-atp-ranking-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/2022-atp-ranking/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-2022-atp-ranking-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-backhand-down-the-line-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/backhand-down-the-line/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-backhand-down-the-line-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-backhand-improvement-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/backhand-improvement/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-backhand-improvement-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-backhand-short-slice-ball-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/backhand-short-slice-ball/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-backhand-short-slice-ball-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-chance-ball-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/chance-ball/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-chance-ball-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-contact-point-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/contact-point/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-contact-point-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-cross-rarry-footwork-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/cross-rarry-footwork/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-cross-rarry-footwork-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-delpo-forehand-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/delpo-forehand/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-delpo-forehand-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-diet-for-tennis-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/diet-for-tennis/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-diet-for-tennis-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-djokovic-2006-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/djokovic-2006/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-djokovic-2006-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-djokovic-2007-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/djokovic-2007/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-djokovic-2007-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-djokovic-footwork-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/djokovic-footwork/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-djokovic-footwork-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-doubles-basic-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/doubles-basic/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-doubles-basic-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-doubles-fore-fore-fore-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/doubles-fore-fore-fore/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-doubles-fore-fore-fore-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-doubles-tukidama-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/doubles-tukidama/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-doubles-tukidama-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-drop-shot-timing-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/drop-shot-timing/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-drop-shot-timing-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-federer-footwork-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/federer-footwork/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-federer-footwork-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-footwork-against-speed-ball-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/footwork-against-speed-ball/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-footwork-against-speed-ball-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-forehand-opening-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/forehand-opening/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-forehand-opening-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-forehand-straight-spin-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/forehand-straight-spin/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-forehand-straight-spin-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-forehand-weekpoint-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/forehand-weekpoint/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-forehand-weekpoint-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-french-2022-nadal-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/french-2022-nadal/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-french-2022-nadal-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-french-2022-ruud-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/french-2022-ruud/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-french-2022-ruud-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-gut-75-pond-injury-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/gut-75pond-injury/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-gut-75-pond-injury-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-gut-tension-40-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/gut-tension-40/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-gut-tension-40-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-head-boom-2022-review-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/head-boom-2022-review/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-head-boom-2022-review-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-introduction-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/introduction/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-introduction-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-minor-rules-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/minor-rules/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-minor-rules-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-nadal-footwork-open-court-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/nadal-footwork-open-court/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-nadal-footwork-open-court-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-nadal-forehand-footwork-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/nadal-forehand-footwork/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-nadal-forehand-footwork-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-noadvantage-tiebreak-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/noadvantage-tiebreak/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-noadvantage-tiebreak-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-preparation-speed-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/preparation-speed/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-preparation-speed-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-relaxation-forehand-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/relaxation-forehand/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-relaxation-forehand-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-round-forehand-straight-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/round-forehand-straight/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-round-forehand-straight-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-seeing-the-ball-on-hit-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/seeing-the-ball-on-hit/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-seeing-the-ball-on-hit-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-serve-hind-leg-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/serve-hind-leg/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-serve-hind-leg-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-serve-practice-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/serve-practice/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-serve-practice-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-serve-wide-weak-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/serve-wide-weak/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-serve-wide-weak-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-short-rally-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/short-rally/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-short-rally-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-single-win-strategies-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/single-win-strategies/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-single-win-strategies-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-speed-mp-2022-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/speed-mp-2022/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-speed-mp-2022-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-stance-after-step-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/stance-after-step/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-stance-after-step-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-step-in-forehand-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/step-in-forehand/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-step-in-forehand-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-straight-or-bent-arm-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/straight-or-bent-arm/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-straight-or-bent-arm-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-surface-after-snow-index-mdx": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/surface-after-snow/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-surface-after-snow-index-mdx" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-three-habits-to-improve-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/three-habits-to-improve/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-three-habits-to-improve-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-unit-turn-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/unit-turn/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-unit-turn-index-md" */),
  "component---src-templates-blog-post-js-content-file-path-content-blog-vibration-stop-pro-player-index-md": () => import("./../../../src/templates/blog-post.js?__contentFilePath=/vercel/path0/gatsby/content/blog/vibration-stop-pro-player/index.md" /* webpackChunkName: "component---src-templates-blog-post-js-content-file-path-content-blog-vibration-stop-pro-player-index-md" */),
  "component---src-templates-category-page-js": () => import("./../../../src/templates/category-page.js" /* webpackChunkName: "component---src-templates-category-page-js" */),
  "component---src-templates-posts-page-js": () => import("./../../../src/templates/posts-page.js" /* webpackChunkName: "component---src-templates-posts-page-js" */),
  "component---src-templates-racket-search-page-js-content-file-path-content-search-rackets-md": () => import("./../../../src/templates/racket-search-page.js?__contentFilePath=/vercel/path0/gatsby/content/search/rackets.md" /* webpackChunkName: "component---src-templates-racket-search-page-js-content-file-path-content-search-rackets-md" */),
  "component---src-templates-racket-search-props-page-js": () => import("./../../../src/templates/racket-search-props-page.js" /* webpackChunkName: "component---src-templates-racket-search-props-page-js" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tag-page.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

